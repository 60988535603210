import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Section } from "../../components/Core";
import PostCard from "../../components/PostCard";
import Pagination, { PageItem } from "../../components/Pagination";

import { getProperty } from "../../utils/helperFn";
import moment from "moment";
import ReactMarkdown from 'react-markdown';
import CheckAvailability from "../../components/Rebrand/CheckAvailability/CheckAvailability";

const BlogList = (props) => {

    const gfm = require('remark-gfm')

    const [currentPage, setCurrentPage] = useState(1)

    const articles = getProperty(props.articles, 'allStrapiNewsArticles')

    const sortedArticles = articles.edges.sort((a, b) => (new Date(b.node.published_at).getTime()) - (new Date(a.node.published_at).getTime()));

    const totalPages = Math.ceil(sortedArticles.length / 8);


    const renderNewsArticles = () => {
        return sortedArticles.slice(((currentPage - 1) * 8), (currentPage * 8)).map(article => (
            <Col lg="3" className="mb-5" key={article.node.title}>
                <PostCard
                    img={article.node.image_featured?.childImageSharp.fluid.src}
                    alt={article.node.image_alt ? article.node.image_alt : "YouFibre Broadband"}
                    preTitle={moment(article.node.published_at).format('ll')}
                    title={article.node.title.length > 50 ? article.node.title.substring(0, 50) + '...' : article.node.title}
                    slug={article.node.slug}
                    readMore
                >
                    <ReactMarkdown plugins={[gfm]} className="blog-list-excerpt" source={article.node.content.substring(0, 105) + '...'} />
                </PostCard>
            </Col>
        ))
    }

    const nextPage = () => {
        setCurrentPage(currentPage + 1)
        scrollToTop()
    }

    const scrollToTop = () => {
        if (typeof document !== "undefined") {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
    }

    const prevPage = () => {
        setCurrentPage(currentPage - 1)
        scrollToTop()
    }

    return (
        <>
            <Section>
                <Container>
                    <Row>
                        {renderNewsArticles()}
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <Pagination className="justify-center">
                                {
                                    (currentPage !== 1) &&
                                    <PageItem onClick={() => prevPage()}>
                                        <FaAngleLeft />
                                    </PageItem>
                                }
                                {
                                    Array.from(Array(totalPages).keys()).map(element => {
                                        return <PageItem onClick={() => {
                                            setCurrentPage(element + 1)
                                            scrollToTop()
                                        }} active={(element + 1) == currentPage}>{element + 1}</PageItem>
                                    })
                                }
                                {
                                    (totalPages !== currentPage) &&
                                    <PageItem onClick={() => nextPage()}>
                                        <FaAngleRight />
                                    </PageItem>
                                }
                            </Pagination>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <CheckAvailability center bg="green-bg" title={'Are we right up your street?'} />
        </>
    )
}

export default BlogList
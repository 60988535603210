import React from "react";
import BlogList from "../../sections/blog/BlogList";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo/Seo";
import HeroNews from "../../components/Rebrand/Hero/HeroNews";

const News = () => {

  const data = useStaticQuery(graphql`
        query allNewsArticlesQuery {
          allStrapiNewsArticles(sort: {order: DESC, fields: strapiId}) {
            edges {
              node {
                id
                strapiId
                published_at
                title
                content
                slug
                image_alt
                image_featured {
                  childImageSharp {
                    fluid(maxWidth: 725) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
    `)

  return (
    <>
      <Seo page="news" />
      <HeroNews />
      <BlogList articles={data} />
    </>
  );
};
export default News;

import React from "react";
import styled from "styled-components";
import { Pagination as BSPagination } from "react-bootstrap";

const PaginationStyled = styled(BSPagination)`
  .page-item {
    margin: 0 5px;
    &:first-child .page-link, &:last-child .page-link {
      border-radius: 50%;  
    }
    &.active .page-link {
      background-color: #07131e;
      border: 1px solid #07131e;  
    }
  }
  .page-link {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    color: #07131e;
    border: 1px solid #07131e;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover,
    &:focus {
      z-index: 2;
      color: #ffffff;
      border: 1px solid #07131e;
      background-color: #07131e;
      box-shadow: none;
      text-decoration: none !important;
    }
  }
`;

const Pagination = (props) => (
  <>
    <PaginationStyled {...props} />
  </>
);

export default Pagination;

export const PageItem = (props) => (
  <>
    <PaginationStyled.Item {...props} />
  </>
);

import React from "react";
import { Link } from "gatsby";

const PostCard = ({
    slug,
    img,
    alt,
    preTitle,
    title,
    children,
    readMore
}) => (
    <div className="news-wrapper">
        <Link to={`/news/${slug}/`}>
            <img src={img} alt={alt ? alt : "YouFibre Broadband"} />
        </Link>
        <div className="news-container">
            <p className="news-pretitle">{preTitle}</p>
            <Link to={`/news/${slug}/`} className="news-title">
                <h4 className="mb-3 mt-3">{title}</h4>
            </Link>
            <p className="news-content">{children}</p>
            <br />
            {readMore && (
                <Link to={`/news/${slug}/`} className="news-readmore">
                    Read more
                </Link>
            )}
        </div>
    </div>
);

export default PostCard;
